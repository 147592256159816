<template>
    <div>
        <el-main>
            <el-button type="primary" style="margin-bottom: 10px" size="small"
                @click="$router.push({ path: '/marketing/repairAddAnnouncement' })">+添加公告</el-button>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="公告标题：">
                    <el-input size="small" placeholder="请输入公告标题" v-model="searchForm.title"></el-input>
                </el-form-item>
                <el-form-item label="公告分类：">
                    <el-select size="small" v-model="searchForm.classify_id" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in classify" :key="item.id" :label="item.classify_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否显示：">
                    <el-select size="small" v-model="searchForm.is_show" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " label-width="60px">
                    <el-button type="primary" size="small" @click="getList">搜索</el-button>
                    <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>

            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column label="排序" align="center" prop="sort"></el-table-column>
                <el-table-column prop="title" label="公告标题" min-width="120"></el-table-column>
                <el-table-column prop="subtitle" label="公告副标题" align="center" min-width="120"></el-table-column>
                <el-table-column prop="classify_name" label="分类名称" align="center" min-width="120"></el-table-column>
                <el-table-column prop="is_show" label="显示" align="center" min-width="120">
                    <template slot-scope="scope">
                        <el-switch size="small" disabled v-model="scope.row.is_show" :active-value="1" :inactive-value="0">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" align="center" width="150">
                    <template v-slot="{ row }">{{ row.update_time | getDateformat }}</template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="edit(scope.row.id)" type="text" size="small">编辑</el-button>
                        <el-button @click="delItem(scope.row.id)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            interval: '',
            key: new Date(),

            searchForm: {
                page: 1,
                rows: 10,
                title: '',
                classify_id: '',
                is_show: '',
            },
            classify: [],
            total_number: 0
        };
    },
    filters: {
        getDateformat (val) {
            if (val) {
                return getDateformat(val);
            } else {
                return '--';
            }
        },
    },
    created () {
        this.getClassify()
        this.getList();
    },
    methods: {
        getClassify () {
            this.$axios.post(this.$api.repair.marketing.afficheClassifylists).then(res => {
                if (res.code == 0) {
                    this.classify = res.result;
                }
            });
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        cancelSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                title: '',
                classify_id: '',
                is_show: '',
            };
            this.getList();
        },
        getList () {
            let data = Object.assign({}, this.searchForm);
            this.$axios.post(this.$api.repair.marketing.afficheList, data).then(res => {
                if (res.code == 0) {
                    this.total_number = res.result.total_number;
                    this.key = new Date();
                    this.list = res.result.list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        delItem (id) {
            this.$confirm('确定要删除此数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$axios.post(this.$api.repair.marketing.afficheDel, { id }).then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => { });
        },
        edit (id) {
            this.$router.push(
                {
                    path: '/marketing/repairEditAnnouncement',
                    query: { id }
                }
            )
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
}

.el-table {
    .userInfo {
        display: flex;
        align-items: center;

        .el-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
}

.row {
    display: flex;
    align-items: center;

    .icon {
        font-size: 18px;
        margin-left: 10px;
        color: #409eff;
        margin-top: 2px;
    }
}

.el-form-search {
    margin-bottom: 25px;
}

.formBtn {
    height: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.cardUl {
    width: 100%;
    background-color: #fff;
    margin-top: 50px;
    padding: 50px 0;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
        width: 45%;
        display: flex;
        justify-content: space-between;

        span {
            font-size: 14px;
            color: gray;
        }
    }

    .cardLi {
        width: 80%;
        border: 20px solid #bbbbbb;
        position: relative;
        margin-bottom: 50px;

        .cardLiTil {
            background-color: #f0eeee;
            color: gray;
            font-size: 18px;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .cardLiTab {
            padding: 20px;
        }

        .cardTabPage {
            display: flex;
            justify-content: center;

            position: absolute;
            bottom: 20px;
            left: 40%;
        }
    }
}

.el-form-item {
    margin-bottom: 10px;
}

.rejection {
    width: 100%;
    background-color: #fff;
    border: 2px solid #000;

    .rejectionTil {
        padding: 10px 20px;
        border-bottom: 1px solid #ccc;

        span {
            background-color: #f0eeee;
        }
    }

    .rejectionPage {
        height: 50px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

.rejectionTab {
    padding: 20px;

    div {
        margin-bottom: 10px;
        font-size: 14px;
    }
}

.rejectionPage {
    display: flex;
    justify-content: flex-end;
}
</style>
